import React, { useState, useEffect, useContext } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
  json,
} from "react-router-dom"
import { GlobalContext } from '../GlobalContext'
import { validateForm } from '../utils/validateForm'
import Button from '../formfields/Button'
import Text from '../formfields/Text'
import AuditorForm from './AuditorForm'
import LoginButtons from './LoginButtons'
import Spinner from '../ui-elements/Spinner'

type ObjectAny = {
  [key: string]: any
}

type Props = {
}



function SplashPage({ }: Props) {
  const {
    brandstyles,
    socket,
    sendMessageToWebsocket,
    currentOrganisation,
    setCurrentOrganisation,
    auditorName,
    setAuditorName,
    error,
    qrCodeId
  } = useContext(GlobalContext)

  const [showForm, setShowForm] = useState(false)


  return <div className={`w-full max-w-lg p-4 flex flex-col justify-center`}>

    {error && <p>Error: {error}</p>}


    {!qrCodeId && <p>Sorry - You have accessed this page without providing a valid QR Code Id.</p>}
    {qrCodeId && !error && !currentOrganisation && <Spinner><p>Loading...</p></Spinner>}


    {currentOrganisation && qrCodeId &&!error && <div>
      {showForm ?
        <AuditorForm
          setShowForm={setShowForm}
        />
        :
        <LoginButtons
          setShowForm={setShowForm}
        />
      }
    </div>}

  </div>
}

export default SplashPage
