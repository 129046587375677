import React, { useState, useEffect, useContext } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
  json,
} from "react-router-dom"
import { GlobalContext } from '../GlobalContext'
import { validateForm } from '../utils/validateForm'
import Button from '../formfields/Button'
import Text from '../formfields/Text'
import AuditorForm from './AuditorForm'
import LoginButtons from './LoginButtons'

type ObjectAny = {
  [key: string]: any
}

type Props = {
  logOut: () => void
}



function Header({
  logOut
}: Props) {
  const {
    brandstyles,
    socket,
    sendMessageToWebsocket,
    currentOrganisation,
    setCurrentOrganisation,
    auditorName,
    setAuditorName,
    tableData,
    error,
    qrCodeId,
    setQrCodeId
  } = useContext(GlobalContext)









  return <header className={`w-full max-w-lg`}>
    <div className={`w-full p-4 flex flex-row items-center justify-between`}>
      <Link to={`/`}><img src={`/logo-white-no-strapline.svg`} alt='ChecQR logo' className={`w-48`} /></Link>
      <div className={`text-xs`}>
        <h3 className={`font-righteous text-4xl`}>Audit app</h3>
        {/* <p>{qrCodeId ? qrCodeId : 'no qr code'}</p> */}
        <p>{auditorName ? auditorName : 'Not logged in'}</p>
        <p>{currentOrganisation && tableData && tableData['Organisation'] && tableData['Organisation']['OrganisationName']  ? tableData['Organisation']['OrganisationName'] : ''}</p>
        {/* <p>{currentOrganisation  ? currentOrganisation : 'No org id'}</p> */}
        {auditorName && <button onClick={logOut} className={`underline hover:opacity-80`}>Log out</button>}
      </div>
    </div>
  </header>
}

export default Header
