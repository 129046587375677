import React, { useState, useEffect, useContext } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
  json,
} from "react-router-dom"
import { subscribe } from "../utils/pubsub"
import { GlobalContext } from '../GlobalContext'
import { validateForm } from '../utils/validateForm'
import Button from '../formfields/Button'
import Text from '../formfields/Text'
import Spinner from '../ui-elements/Spinner'

type ObjectAny = {
  [key: string]: any
}


type AuditorFormProps = {
  setShowForm: React.Dispatch<React.SetStateAction<any>>
}



function AuditorForm({
  setShowForm
}: AuditorFormProps) {

  const {
    brandstyles,
    sendMessageToWebsocket,
    currentOrganisation,
    socket,
    auditorName,
    setAuditorName
  } = useContext(GlobalContext)

  const initialFormData = { "AuditorName": { "value": '', "required": true, "type": "text" } }
  const [formFields, setFormFields] = useState<ObjectAny>(initialFormData)
  const [savingProgress, setSavingProgress] = useState(false)
  const navigate = useNavigate()

  const handleChange = (key: string, value: any) => {
    const newFieldValues: ObjectAny = { ...formFields }
    newFieldValues[key] = { ...newFieldValues[key], 'value': value }
    setFormFields(newFieldValues)
  }

  const saveData = () => {
    if (formFields && formFields['AuditorName'] && formFields['AuditorName']['value']) {
      setSavingProgress(true)
      const payload = JSON.stringify({
        action: 'audit',
        auditAction: 'saveAuditorName',
        AuditorName: formFields['AuditorName']['value']
      })
      sendMessageToWebsocket(payload)

      const unsubscribe = subscribe("auditorNameSaved", data => {
        setSavingProgress(false)
        setAuditorName(data)
        localStorage.setItem('auditorName', data)
        navigate('/listreadings')
        unsubscribe()
      })

    } else {
      console.log(`❌ error: No name provided`)
    }
  }

  return <div className={`w-full flex flex-col gap-4`}>

    {savingProgress ?
      <Spinner><p>Saving...</p></Spinner>
      :
      <>
        <Text
          formFields={formFields}
          labelText={'Your name:'}
          handleChange={handleChange}
          fieldName={'AuditorName'}
          placeholder={''}
          errorMessage={'Please enter your name'}
          size={'big'}
          center={true}
        />
        <div className={`w-full flex flex-col-reverse md:flex-row gap-2 items-center justify-center`}>
          <Button
            onClick={() => setShowForm(false)}
            text={'Cancel'}
            variant={`gray`}
            size={`big`}
          />
          <Button
            onClick={(e: any) => validateForm(e, formFields, setFormFields, saveData)}
            text={'Submit'}
            variant={`secondary`}
            size={`big`}
          />
        </div>
      </>
    }


  </div>
}



export default AuditorForm
