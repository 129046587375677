import React, { useState, useEffect, useContext } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
  json,
} from "react-router-dom"
import { GlobalContext } from '../GlobalContext'
import { validateForm } from '../utils/validateForm'
import Button from '../formfields/Button'
import Text from '../formfields/Text'

type ObjectAny = {
  [key: string]: any
}


type ButtonsProps = {
  setShowForm: React.Dispatch<React.SetStateAction<any>>
}


function LoginButtons({
  setShowForm
}: ButtonsProps) {

  const {
    brandstyles,
    sendMessageToWebsocket,
    currentOrganisation,
    setCurrentOrganisation,
    socket,
    auditorName
  } = useContext(GlobalContext)


  return <div className={`w-full flex flex-col md:flex-row gap-4 items-center justify-center`}>
    <div className={`w-full flex-1 flex flex-col items-center gap-2`}>
      <p className={`mt-2 text-sm`}>Site management:</p>
      <Button
        externalLinkUrl={`https://dashboard.checqr.net/`}
        text={'Go to dashboard'}
        variant={`secondary`}
        size={`big`}
      />
    </div>
    <div className={`w-full flex-1  flex flex-col items-center gap-2`}>
      <p className={`mt-2 text-sm`}>External Auditor:</p>
      {auditorName ? <Button
        internalLinkUrl={`/listreadings`}
        text={'View data'}
        variant={`secondary`}
        size={`big`}
      /> :
        <Button
          onClick={() => setShowForm(true)}
          text={'Log in to view data'}
          variant={`secondary`}
          size={`big`}
        />}
    </div>
  </div>
}




export default LoginButtons
