import React, { useContext } from 'react'
import { GlobalContext } from '../GlobalContext'

type Props = {
  clickFunction: any
}


function XButton({
  clickFunction
}: Props) {

  const {
    brandstyles
  } = useContext(GlobalContext)

  return <button
    onClick={clickFunction}
    className={`outline-none border-none cursor-pointer rounded bg-gray-300 w-6 h-6 font-white flex items-center justify-center hover:opacity-80`}
  >✕</button>
}

export default XButton